import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface BundesanzeigerSearchJobData {
  company_name: string;
  fy_start: number | null;
  fy_end: number | null;
}

export interface BundesanzeigerSearchJobList {
  id: string;
  org_id: string;
  deal_id: string;
  status: "pending" | "in_progress" | "completed" | "failed";
  type: "bundesanzeiger_search";
  data: BundesanzeigerSearchJobData;
  documents_uploaded: number;
  created_at: string;
  updated_at: string;
}

export const listBundesanzeigerSearchJobs = async (
  user: User,
  orgId: string,
  dealId: string,
): Promise<BundesanzeigerSearchJobList[]> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/bundesanzeiger-search-jobs`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};

export interface BundesanzeigerSearchJobRead {
  id: string;
  org_id: string;
  deal_id: string;
  status: "pending" | "in_progress" | "complete" | "failed";
  data: BundesanzeigerSearchJobData;
  documents_uploaded: number;
  created_at: string;
  updated_at: string;
}

export const readBundesanzeigerSearchJob = async (
  user: User,
  orgId: string,
  dealId: string,
  bundesanzeigerSearchJobId: string,
): Promise<BundesanzeigerSearchJobRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/bundesanzeiger-search-jobs/${bundesanzeigerSearchJobId}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};

export interface BundesanzeigerSearchJobCreate {
  company_name: string;
  fy_start: number | null;
  fy_end: number | null;
}

export const createBundesanzeigerSearchJob = async (
  user: User,
  orgId: string,
  dealId: string,
  data: BundesanzeigerSearchJobCreate,
): Promise<BundesanzeigerSearchJobRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/bundesanzeiger-search-jobs`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify(data),
      },
    ),
  );
};

export const deleteBundesanzeigerSearchJob = async (
  user: User,
  orgId: string,
  dealId: string,
  bundesanzeigerSearchJobId: string,
): Promise<null> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/bundesanzeiger-search-jobs/${bundesanzeigerSearchJobId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};
