import { useAuth, defaultRetryFunc } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import { readCompanyFinancials } from "../api/readCompanyFinancials";

export const useCompanyFinancialsQuery = (orgId: string, id: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "companyFinancials", id],
    queryFn: () => readCompanyFinancials(user, orgId, id),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};
