import type React from "react";
import styles from "./TextField.styles";
import {
  TextField as AriaTextField,
  Label,
  Input,
  FieldError,
} from "react-aria-components";
import { useEffect, useRef, useState } from "react";

export type TextFieldType = "text" | "url" | "tel" | "email" | "password";
export type TextFieldInputMode =
  | "none"
  | "text"
  | "tel"
  | "url"
  | "email"
  | "numeric"
  | "decimal"
  | "search";
export type TextFieldSize = "sm" | "md";

export interface TextFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  type?: TextFieldType;
  inputMode?: TextFieldInputMode;
  required?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  name?: string;
  placeholder?: string;
  size?: TextFieldSize;
  validationPattern?: string;
  customValidation?: (value: string) => string | null;
  autoHighlight?: boolean;
  _forceInvalid?: boolean;
}

export const TextField = ({
  label,
  value,
  onChange,
  disabled,
  type = "text",
  inputMode = "text",
  required,
  autoComplete = "off",
  autoFocus,
  name,
  placeholder,
  size = "md",
  validationPattern,
  customValidation,
  autoHighlight,
  _forceInvalid,
}: TextFieldProps) => {
  const [focused, setFocused] = useState(false);
  const { base, inputStyle, labelStyle, asterisk, fieldError } = styles({
    size,
    focused,
  });
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoHighlight) {
      inputRef.current?.select();
    }
  }, []);

  return (
    <AriaTextField
      value={value}
      className={({ isDisabled }) => base({ isDisabled })}
      onChange={onChange}
      isDisabled={disabled}
      type={type}
      inputMode={inputMode}
      isRequired={required}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      name={name || label}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      pattern={validationPattern}
      validate={customValidation}
      isInvalid={_forceInvalid}
    >
      {({ isInvalid }) => (
        <>
          <div className="w-full flex justify-between">
            <Label className={labelStyle()}>
              {label}
              {required && <span className={asterisk()}>*</span>}
            </Label>
            <FieldError className={fieldError()} />
            {/* Only for testing */}
            {_forceInvalid && (
              <FieldError className={fieldError()}>Invalid Field</FieldError>
            )}
          </div>
          <Input
            placeholder={placeholder}
            className={inputStyle({ isInvalid })}
            ref={inputRef}
          />
        </>
      )}
    </AriaTextField>
  );
};
