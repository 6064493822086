import { withVariants } from "@/utils/withVariants";

const styles = withVariants({
  slots: {
    wrapper: "flex items-center gap-1.5",
    item: "text-subdued text-size-label flex items-center gap-1.5",
    arrow: "text-label",
    link: "clickable pressable outline-none rounded-badge",
  },
  variants: {
    isDisabled: {
      true: {
        item: "pointer-events-none font-strong text",
      },
    },
  },
});

export default styles;
