import React from "react";
import Breadcrumb, { BreadcrumbItem } from "./Breadcrumb";

interface Props {
  items: BreadcrumbItem[];
  buttons?: React.ReactNode[];
  children: React.ReactNode;
}

const TabLayout: React.FC<Props> = ({ items, buttons, children }) => {
  return (
    <>
      <div className="flex flex-row items-center overflow-auto">
        <Breadcrumb items={items} />
        <div className="flex flex-row ml-auto h-8 mr-4 space-x-2">
          {buttons}
        </div>
      </div>
      <div
        id="tab-body"
        className="bg-white h-[calc(100vh-7.5rem)] sm:h-[calc(100vh-3.5rem)] overflow-auto border-t-[1px] border-gray-200"
      >
        {children}
      </div>
    </>
  );
};

export default TabLayout;
