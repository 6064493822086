import React, { useMemo } from "react";
import { tabsStyles, tabListStyles, tabStyles } from "./Tabs.styles";
import {
  Tab,
  TabList,
  Tabs as AriaTabs,
  TabPanel,
  Key,
} from "react-aria-components";

export type Tab = {
  label: string;
  disabled?: boolean;
};

export interface TabsProps {
  children?: React.ReactNode;
  tabs: Tab[];
  label?: string;
  selectedTab?: number;
  onSelectTab?: (index: number) => void;
}

export const Tabs = ({
  children,
  tabs,
  label = "list",
  selectedTab = 0,
  onSelectTab,
}: TabsProps) => {
  const selected = useMemo(() => tabs[selectedTab].label, [selectedTab]);

  const TabLabels = useMemo(() => tabs.map((tab) => tab.label), [tabs]);

  const handleChange = (key: Key) => {
    if (onSelectTab) {
      onSelectTab(TabLabels.indexOf(key as string));
    }
  };

  return (
    <AriaTabs
      className={tabsStyles()}
      selectedKey={selected}
      onSelectionChange={handleChange}
    >
      <TabList aria-label={label} className={tabListStyles()}>
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            id={tab.label}
            isDisabled={tab.disabled}
            className={({ isSelected, isDisabled }) =>
              tabStyles({ isSelected, isDisabled })
            }
          >
            {tab.label.toUpperCase()}
          </Tab>
        ))}
      </TabList>
      {React.Children.map(children, (child, index) => (
        <TabPanel
          key={index}
          id={tabs[index].label}
          className="outline-none !shadow-none animate-none"
        >
          {child}
        </TabPanel>
      ))}
    </AriaTabs>
  );
};
