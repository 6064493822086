import FileUploadComponent from "@/components/DealsTab/subcomponents/UploadDocuments";
import { Button } from "@/components/ui/Button";
import { useCreateCompanyStore } from "@/features/companies/store/useCreateCompanyStore";
import React from "react";

const TabFiles = ({ onCreateDeal }: { onCreateDeal: () => void }) => {
  const { previousTab, setFiles } = useCreateCompanyStore();

  const handleFiles = (files: FileList) => {
    setFiles(files);
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <FileUploadComponent onFileUpload={handleFiles} />
      </div>
      <div className="flex gap-1.5">
        <Button
          variant="secondary"
          iconLeft="chevronLeft"
          onClick={previousTab}
        >
          Back
        </Button>
        <div className="flex-1" />
        <Button iconRight="chevronRight" onClick={onCreateDeal}>
          Create Deal
        </Button>
      </div>
    </>
  );
};

export default TabFiles;
