import { withVariants } from "@/utils/withVariants";

const styles = withVariants({
  slots: {
    listStyle:
      "flex gap-1 min-h-6 flex-wrap focus-none outline-none focus:border-none",
    tagStyle:
      "outline-none text-label text-size-label font-strong bg-primary-selected rounded flex py-0.5 px-2 items-center justify-center gap-1.5",
    cancelIcon: "outline-none clickable pressable text-primary-subdued",
  },
  variants: {
    allowsRemoving: {
      true: {
        tagStyle: "pr-1",
      },
    },
  },
});

export default styles;
