import { withVariants } from "@/utils/withVariants";

const styles = withVariants({
  base: "clickable pressable relative outline-none rounded appearance-none font-strong flex items-center justify-center shrink-0",
  variants: {
    variant: {
      primary: "bg-primary-inverse text-oncolor",
      secondary: "bg text-label border",
      tertiary: "bg bg-opacity-0 text-label hover:bg-opacity-50",
      danger: "bg-danger-inverse text-oncolor",
    },
    size: {
      sm: "h-6 px-1.5 gap-1 text-size-label",
      md: "h-7 px-2 gap-1.5 text-size-body",
      lg: "h-9 px-3 gap-2 text-size-subheading",
    },
    isPending: {
      true: "pointer-events-none text-transparent",
    },
    isDisabled: {
      true: "disabled",
    },
  },
});

export default styles;
