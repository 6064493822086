import { withVariants } from "@/utils/withVariants";

const styles = withVariants({
  slots: {
    wrapper: "w-full flex items-start gap-6 px-3 py-2 border-b last:border-b-0",
    content: "flex-1 display flex flex-col",
  },
});

export default styles;
