import type React from "react";
import styles from "./IconButton.styles";
import { Icon, IconType } from "@/components/ui/Icon";
import { Button as AriaButton, type PressEvent } from "react-aria-components";
import { useMemo } from "react";
import { Spinner } from "@/components/ui/Spinner";

export type IconButtonType = "button" | "submit" | "reset";
export type IconButtonVariant = "primary" | "secondary" | "tertiary" | "danger";
export type IconButtonSize = "sm" | "md" | "lg";

export interface IconButtonProps {
  icon: IconType;
  /**
   * 	Handler that is called when the press is released over the target.
   */
  onClick: (e: PressEvent) => void;
  /**
   * The behavior of the button when used in an HTML form.
   */
  type?: IconButtonType;
  variant?: IconButtonVariant;
  size?: IconButtonSize;
  disabled?: boolean;
  loading?: boolean;
  /**
   * The value associated with the button's name when it's submitted with the form data.
   */
  value?: string;
}

export const IconButton = ({
  icon,
  onClick,
  disabled,
  loading,
  value,
  type = "button",
  variant = "primary",
  size = "md",
}: IconButtonProps) => {
  const iconSize = useMemo(() => {
    switch (size) {
      case "sm":
        return "xs";
      case "md":
        return "sm";
      case "lg":
        return "md";
    }
  }, [size]);

  const spinnerVariant = useMemo(() => {
    switch (variant) {
      case "primary":
      case "danger":
        return "oncolor";
      case "secondary":
      case "tertiary":
        return "default";
    }
  }, [variant]);

  return (
    <AriaButton
      className={({ isPending, isDisabled }) =>
        styles({ variant, size, isPending, isDisabled })
      }
      onPress={onClick}
      type={type}
      isDisabled={disabled}
      isPending={loading}
      value={value}
    >
      {({ isPending }) => (
        <>
          <Icon type={icon} size={iconSize} />
          {isPending && (
            <span className="absolute">
              <Spinner variant={spinnerVariant} size={iconSize} />
            </span>
          )}
        </>
      )}
    </AriaButton>
  );
};
