import NavigationIconCard from "../base/NavigationIconCard";
import { useParams } from "react-router-dom";
import TabLayout from "../TabLayout";
import {
  DocumentPlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";

type URLParams = {
  orgId: string;
};

export default function Templates() {
  const { orgId } = useParams() as URLParams;

  return (
    <TabLayout
      items={[{ name: "Templates", href: `/orgs/${orgId}/templates` }]}
    >
      <div className="h-full overflow-auto p-4">
        <div className="flex flex-row flex-wrap">
          <NavigationIconCard
            name="Data Extraction Templates"
            icon={DocumentPlusIcon}
            description="Create and manage Data Extraction Templates."
            href={`/orgs/${orgId}/company-profile-templates`}
          />
          <NavigationIconCard
            name="Question Thread Templates"
            icon={QuestionMarkCircleIcon}
            description="Create and manage question thread templates."
            href={`/orgs/${orgId}/question-templates`}
          />
        </div>
      </div>
    </TabLayout>
  );
}
