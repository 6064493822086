import type React from "react";
import styles, { iconStyle } from "./EditableHeading.styles";
import { useEffect, useRef, useState } from "react";
import { FocusRing } from "react-aria";
import { Icon } from "@/components/ui/Icon";

export type EditableHeadingType = "h1" | "h2" | "h3";

export interface EditableHeadingProps {
  type?: EditableHeadingType;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  autofocus?: boolean;
}

export const EditableHeading = ({
  type = "h1",
  value,
  onChange,
  placeholder = "Untitled",
  autofocus,
}: EditableHeadingProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const headingRef = useRef<HTMLHeadingElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLHeadingElement>) => {
    if (e.key === "Enter" && headingRef.current) {
      e.preventDefault();
      headingRef.current.blur();
    }
  };

  const handleInput = (e: React.FormEvent<HTMLHeadingElement>) => {
    if (e.currentTarget.textContent) {
      onChange(e.currentTarget.textContent);
    }
  };

  const focusInput = () => {
    if (headingRef.current) {
      headingRef.current.focus();
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(headingRef.current);
      range.collapse(false);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  };

  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLHeadingElement>) => {
    handleInput(e);
    setIsEditing(false);
  };

  useEffect(() => {
    if (autofocus) {
      focusInput();
    }
  }, []);

  const HeadingTag = type;
  return (
    <div className="flex items-center gap-1.5 text-label cursor-text">
      <FocusRing focusRingClass="focus">
        <HeadingTag
          ref={headingRef}
          contentEditable
          suppressContentEditableWarning={true}
          aria-placeholder={placeholder}
          data-placeholder={placeholder}
          className={styles({ type, isEditing })}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onFocus={handleFocus}
        >
          {value}
        </HeadingTag>
      </FocusRing>
      <div onClick={focusInput} className={iconStyle({ isEditing })}>
        <Icon type="edit" size="sm" />
      </div>
    </div>
  );
};
