import { withVariants } from "@/utils/withVariants";

const styles = withVariants({
  slots: {
    wrapper: "flex items-center",
    border: "border-b",
  },
  variants: {
    variant: {
      default: {
        wrapper: "py-0",
      },
      padded: {
        wrapper: "",
      },
    },
    direction: {
      vertical: {
        wrapper: "h-full",
        border: "h-full w-px border-r",
      },
      horizontal: {
        wrapper: "w-full",
        border: "w-full h-px border-b",
      },
    },
  },
  compoundVariants: [
    {
      direction: "horizontal",
      variant: "default",
      class: {
        wrapper: "py-0",
      },
    },
    {
      direction: "horizontal",
      variant: "padded",
      class: {
        wrapper: "py-1",
      },
    },
    {
      direction: "vertical",
      variant: "default",
      class: {
        wrapper: "px-0",
      },
    },
    {
      direction: "vertical",
      variant: "padded",
      class: {
        wrapper: "px-1",
      },
    },
  ],
});

export default styles;
