import { withVariants } from "@/utils/withVariants";

export const listStyles = withVariants({
  slots: {
    base: "",
  },
});

export const listItemStyles = withVariants({
  slots: {
    baseStyle:
      "transition focus-tight bg cursor-pointer outline-none py-2 px-3 flex items-center gap-3 border-b last:border-b-0",
    contentStyle: "flex flex-1 flex-col text-size-label text",
    descriptionStyle: "text-label text-size-caption",
    iconStyle: "text-label",
    arrowStyle: "text-subdued",
    avatarStyle: "h-icon-lg w-icon-lg rounded-element",
  },
  variants: {
    isSelected: {
      true: {
        baseStyle: "bg-primary border-b-primary",
        iconStyle: "text-primary",
        arrowStyle: "text-primary",
      },
    },
    isFocused: {
      true: {
        baseStyle: "bg-selected",
      },
    },
    selectable: {
      true: {
        baseStyle: "clickable",
      },
    },
  },
  compoundVariants: [
    {
      isSelected: true,
      isFocused: true,
      baseStyle: "bg-primary-selected",
    },
  ],
});
