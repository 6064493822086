import { withVariants } from "@/utils/withVariants";

const styles = withVariants({
  base: "fill-current",
  variants: {
    size: {
      xs: "h-icon-xs w-icon-xs",
      sm: "h-icon-sm w-icon-sm",
      md: "h-icon-md w-icon-md",
      lg: "h-icon-lg w-icon-lg",
      hero: "h-10 w-10",
    },
  },
});

export const backgroundStyle = withVariants({
  base: "bg-primary flex justify-center items-center text-subdued w-fit h-fit",
  variants: {
    size: {
      xs: "p-0.5 rounded-badge",
      sm: "p-1 rounded-badge",
      md: "p-1.5 rounded",
      lg: "p-2 rounded",
      hero: "p-2 rounded-layout",
    },
  },
});

export default styles;
