import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useCreateBundesanzeigerSearchJobMutation } from "../../hooks/bundesanzeigerSearchJob";
import { useState } from "react";
import { useCreateCompaniesHouseSearchJobMutation } from "../../hooks/companiesHouseSearchJob";
import { useDealQuery } from "../../hooks/deals";
import TabLayout from "../TabLayout";

type URLParams = {
  orgId: string;
  dealId: string;
};

function BundesanzeigerSearchForm({
  orgId,
  dealId,
}: {
  orgId: string;
  dealId: string;
}) {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      companyName: "",
      fyStart: (new Date().getFullYear() - 5).toString(),
      fyEnd: "",
    },
  });
  const createBundesanzeigerSearchJobMutation =
    useCreateBundesanzeigerSearchJobMutation(orgId, dealId);
  const navigate = useNavigate();
  return (
    <form
      className="px-1"
      onSubmit={handleSubmit((values) => {
        createBundesanzeigerSearchJobMutation.mutate(
          {
            company_name: values.companyName,
            fy_start: isNaN(parseInt(values.fyStart))
              ? null
              : parseInt(values.fyStart),
            fy_end: isNaN(parseInt(values.fyEnd))
              ? null
              : parseInt(values.fyEnd),
          },
          {
            onSuccess: () => {
              navigate(`/orgs/${orgId}/deals/${dealId}/registry-search-jobs`);
              reset();
            },
          },
        );
      })}
    >
      <div className="sm:col-span-6 mt-6">
        <label
          htmlFor="companyName"
          className="block text-sm font-medium text-gray-700"
        >
          Legal Entity Name
        </label>
        <div className="mt-1">
          <Controller
            name="companyName"
            control={control}
            render={({ field }) => (
              <input
                id="companyName"
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...field}
                value={field.value ?? ""}
              />
            )}
          />
        </div>
      </div>
      <div className="sm:col-span-6 mt-6">
        <label
          htmlFor="fyStart"
          className="block text-sm font-medium text-gray-700"
        >
          From Financial Year End (optional)
        </label>
        <div className="mt-1">
          <Controller
            name="fyStart"
            control={control}
            render={({ field }) => (
              <input
                id="fyStart"
                type="number"
                min="1900"
                max="2099"
                step="1"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...field}
                value={field.value ?? ""}
              />
            )}
          />
        </div>
      </div>
      <div className="sm:col-span-6 mt-6">
        <label
          htmlFor="fyEnd"
          className="block text-sm font-medium text-gray-700"
        >
          To Financial Year End (optional)
        </label>
        <div className="mt-1">
          <Controller
            name="fyEnd"
            control={control}
            render={({ field }) => (
              <input
                id="fyEnd"
                type="number"
                min="1900"
                max="2099"
                step="1"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...field}
                value={field.value ?? ""}
              />
            )}
          />
        </div>
      </div>
      <div className="py-6">
        <div className="flex justify-end">
          <button
            onClick={() =>
              navigate(`/orgs/${orgId}/deals/${dealId}/registry-search-jobs`)
            }
            type="button"
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Add
          </button>
        </div>
      </div>
    </form>
  );
}

function CompaniesHouseSearchForm({
  orgId,
  dealId,
}: {
  orgId: string;
  dealId: string;
}) {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      companyName: "",
      fyStart: (new Date().getFullYear() - 5).toString(),
      fyEnd: "",
    },
  });
  const createCompaniesHouseSearchJobMutation =
    useCreateCompaniesHouseSearchJobMutation(orgId, dealId);
  const navigate = useNavigate();
  return (
    <form
      className="px-1"
      onSubmit={handleSubmit((values) => {
        createCompaniesHouseSearchJobMutation.mutate(
          {
            company_name: values.companyName,
            fy_start: isNaN(parseInt(values.fyStart))
              ? null
              : parseInt(values.fyStart),
            fy_end: isNaN(parseInt(values.fyEnd))
              ? null
              : parseInt(values.fyEnd),
          },
          {
            onSuccess: () => {
              navigate(`/orgs/${orgId}/deals/${dealId}/registry-search-jobs`);
              reset();
            },
          },
        );
      })}
    >
      <div className="sm:col-span-6 mt-6">
        <label
          htmlFor="companyName"
          className="block text-sm font-medium text-gray-700"
        >
          Legal Entity Name
        </label>
        <div className="mt-1">
          <Controller
            name="companyName"
            control={control}
            render={({ field }) => (
              <input
                id="companyName"
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...field}
                value={field.value ?? ""}
              />
            )}
          />
        </div>
      </div>
      <div className="sm:col-span-6 mt-6">
        <label
          htmlFor="fyStart"
          className="block text-sm font-medium text-gray-700"
        >
          From Financial Year End (optional)
        </label>
        <div className="mt-1">
          <Controller
            name="fyStart"
            control={control}
            render={({ field }) => (
              <input
                id="fyStart"
                type="number"
                min="1900"
                max="2099"
                step="1"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...field}
                value={field.value ?? ""}
              />
            )}
          />
        </div>
      </div>
      <div className="sm:col-span-6 mt-6">
        <label
          htmlFor="fyEnd"
          className="block text-sm font-medium text-gray-700"
        >
          To Financial Year End (optional)
        </label>
        <div className="mt-1">
          <Controller
            name="fyEnd"
            control={control}
            render={({ field }) => (
              <input
                id="fyEnd"
                type="number"
                min="1900"
                max="2099"
                step="1"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                {...field}
                value={field.value ?? ""}
              />
            )}
          />
        </div>
      </div>
      <div className="py-6">
        <div className="flex justify-end">
          <button
            onClick={() =>
              navigate(`/orgs/${orgId}/deals/${dealId}/registry-search-jobs`)
            }
            type="button"
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Add
          </button>
        </div>
      </div>
    </form>
  );
}

export default function RegistrySearchCreate() {
  const { orgId, dealId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const [registry, setRegistry] = useState("bundesanzeiger");

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        {
          name: "Registry Searches",
          href: `/orgs/${orgId}/deals/${dealId}/registry-search-jobs`,
        },
        { name: "Add", href: null },
      ]}
    >
      <div className="p-4">
        <div className="px-1">
          <div className="flex border-b-[1px] border-gray-200 pb-4">
            <p className="text-sm max-w-lg text-gray-700">
              Add a new registry search.
            </p>
          </div>
          <div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor="registry"
                  className="block text-sm font-medium text-gray-700"
                >
                  Registry
                </label>
                <div className="mt-1">
                  <select
                    value={registry}
                    onChange={(e) => setRegistry(e.target.value)}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="bundesanzeiger">
                      Bundesanzeiger & Unternehmensregister
                    </option>
                    <option value="companies_house">Companies House</option>
                  </select>
                </div>
              </div>
            </div>
            {registry === "bundesanzeiger" && (
              <BundesanzeigerSearchForm orgId={orgId} dealId={dealId} />
            )}
            {registry === "companies_house" && (
              <CompaniesHouseSearchForm orgId={orgId} dealId={dealId} />
            )}
          </div>
        </div>
      </div>
    </TabLayout>
  );
}
