import type React from "react";
import styles from "./TagField.styles";
import {
  SearchField as AriaTagField,
  Label,
  Input,
  Text,
} from "react-aria-components";
import { useState } from "react";
import { Tag, Tags } from "@/components/ui/Tags";

export interface TagFieldProps {
  label: string;
  disabled?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  name?: string;
  placeholder?: string;
  description?: string;
  tags: Tag[];
  onRemoveTag: (id: string) => void;
  onAddTag: (label: string) => void;
  defaultValue?: string;
  onEmptyEnter?: () => void;
}

export const TagField = ({
  label,
  disabled,
  autoComplete = "off",
  autoFocus,
  name,
  placeholder,
  description = "Press enter to add a new tag",
  tags,
  onRemoveTag,
  onAddTag,
  defaultValue = "",
  onEmptyEnter,
}: TagFieldProps) => {
  const [value, setValue] = useState(defaultValue);
  const [focused, setFocused] = useState(false);
  const { base, inputStyle, inputWrapper, labelStyle, descriptionStyle } =
    styles();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (value.length > 0) {
        e.preventDefault();
        onAddTag(value);
        setValue("");
      } else if (onEmptyEnter) {
        onEmptyEnter();
      }
    }
  };

  return (
    <AriaTagField
      value={value}
      className={({ isDisabled }) => base({ isDisabled })}
      onChange={setValue}
      isDisabled={disabled}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      name={name || label}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onKeyDown={handleKeyDown}
    >
      <Label className={labelStyle({ focused })}>
        {label}
        <Text slot="description" className={descriptionStyle({ focused })}>
          ({description})
        </Text>
      </Label>
      <div className={inputWrapper()}>
        <Input placeholder={placeholder} className={inputStyle()} />
      </div>
      {tags.length > 0 && <Tags onRemove={onRemoveTag} tags={tags} />}
    </AriaTagField>
  );
};
