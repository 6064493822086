import { APIError } from "../../../api/shared";
import DeleteFileButton from "./DeleteFileButton";
import { useDealFilesQuery, useDealQuery } from "../../../hooks/deals";
import { Link, useParams } from "react-router-dom";
import TabLayout from "../../TabLayout";
import DealAppToolbar from "../../DealAppToolbar";
import UploadFilesButton from "../UploadFilesButton";
import UploadFolderButton from "../UploadFolderButton";
import { FolderIcon } from "@heroicons/react/24/outline";
import Table from "../../Table";
import React from "react";

type URLParams = {
  orgId: string;
  dealId: string;
};

function StatusTag({ status }: { status: string }) {
  if (status === "completed") {
    return (
      <div className="bg-white w-fit p-1 rounded-md">
        <p className="">Processed</p>
      </div>
    );
  }
  if (status === "failed") {
    return (
      <div className="border-red-500 border w-fit p-1 rounded-md">
        <p className=" text-red-500">Failed</p>
      </div>
    );
  }
  return (
    <div className="border-orange-500 border w-fit p-1 rounded-md">
      <p className=" text-orange-500">Processing</p>
    </div>
  );
}

function _FilesList() {
  const { orgId, dealId } = useParams() as URLParams;
  const filesQuery = useDealFilesQuery(orgId, dealId);

  // Insufficient permissions
  if (
    filesQuery.error instanceof APIError &&
    filesQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to list files."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (filesQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">Error listing files.</p>
        </ul>
      </div>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!filesQuery.isLoading) {
    data = filesQuery.data.map((file) => {
      return [
        <Link
          key={file.id}
          to={`/orgs/${orgId}/deals/${dealId}/files/${file.id}`}
          className="text-indigo-600"
        >
          {file.name}
        </Link>,
        <time key={`${file.id}-created_at`} dateTime={file.created_at}>
          {new Date(file.created_at).toLocaleString()}
        </time>,
        <StatusTag key={`${file.id}-status`} status={file.ingestion_status} />,
        <div
          key={`${file.id}-actions`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <DeleteFileButton orgId={orgId} dealId={dealId} fileId={file.id} />
        </div>,
      ];
    });
  }

  return (
    <Table
      columnHeaders={["Name", "Uploaded", "Status", ""]}
      data={data}
      noDataIcon={FolderIcon}
      noDataMessage={"You don't have any files yet."}
      noDataButton={<UploadFilesButton orgId={orgId} dealId={dealId} />}
      isLoading={filesQuery.isLoading}
    />
  );
}

export default function FilesList() {
  const { orgId, dealId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const breadcrumbItems = [
    { name: "Deals", href: `/orgs/${orgId}/deals` },
    {
      name: dealQuery.data?.name || "...",
      href: `/orgs/${orgId}/deals/${dealId}`,
    },
    { name: "Files", href: null },
  ];

  return (
    <TabLayout
      items={breadcrumbItems}
      buttons={[
        <UploadFilesButton
          key="upload-files-button"
          orgId={orgId}
          dealId={dealId}
        />,
        <UploadFolderButton
          key="upload-folder-button"
          orgId={orgId}
          dealId={dealId}
        />,
      ]}
    >
      <div className="p-4">
        <DealAppToolbar
          orgId={orgId}
          dealId={dealId}
          tabItems={[{ name: "Files", active: true }]}
        />
        <_FilesList />
      </div>
    </TabLayout>
  );
}
