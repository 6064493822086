import { withVariants } from "@/utils/withVariants";

export const tabsStyles = withVariants({
  base: "w-full !shadow-none",
});

export const tabListStyles = withVariants({
  base: "flex border-b text-label",
});

export const tabStyles = withVariants({
  base: "clickable pressable focus-tight bg px-3 py-1.5 cursor-pointer outline-none -mb-px font-strong text-size-label border-b",
  variants: {
    isSelected: {
      true: "text-primary border-b border-primary",
    },
    isDisabled: {
      true: "disabled",
    },
  },
});
