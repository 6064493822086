import React from "react";
import {
  BuildingOffice2Icon,
  CalendarDaysIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { People } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import Skeleton from "../../base/Skeleton";
import { useCompanyOverviewQuery } from "../hooks/companyOverview";

interface CompanyOverviewComponentProps {
  legalName: string;
  companyWebsite: string;
}
const SkeletonOverview = () => {
  return (
    <div className="content-left flex flex-col border rounded-lg p-4 space-y-3 h-full w-full">
      <div className="font-bold text-lg">Overview</div>
      <div className="text-sm pr-2">
        <Skeleton width="100%" height="3rem" />
      </div>
      <div className="flex flex-row items-center w-full">
        <BuildingOffice2Icon className="h-6 w-6 mr-2" />
        <Skeleton width="50%" height="3rem" />
      </div>
      <div className="flex flex-row items-center w-full">
        <CalendarDaysIcon className="h-6 w-6 mr-2" />
        <Skeleton width="50%" height="1.5rem" />
      </div>
      <div className="flex flex-row items-center w-full">
        <MapPinIcon className="h-6 w-6 mr-2" />
        <Skeleton width="50%" height="1.5rem" />
      </div>
      <div className="flex flex-row items-center w-full">
        <People className="h-6 w-6 mr-2" />
        <Skeleton width="50%" height="1.5rem" />
      </div>
    </div>
  );
};

interface CompanyOverviewContentsProps {
  description: string;
  publiclyTraded: boolean;
  foundationYear: string;
  headquarters: string;
  employees: string;
}

const CompanyOverviewContents = ({
  description,
  publiclyTraded,
  foundationYear,
  headquarters,
  employees,
}: CompanyOverviewContentsProps) => {
  return (
    <div className="content-left flex flex-col border rounded-lg p-4 space-y-3 h-full">
      <div className="font-bold text-lg">Overview</div>
      <div className="text-sm pr-2">{description}</div>
      <div className="flex flex-row items-center">
        <BuildingOffice2Icon className="h-6 w-6 mr-2" />
        <div className="text-sm">
          {publiclyTraded ? "Public" : "Privately Held"}
        </div>
      </div>
      {foundationYear && (
        <div className="flex flex-row items-center">
          <CalendarDaysIcon className="h-6 w-6 mr-2" />
          <div className="text-sm">{foundationYear}</div>
        </div>
      )}
      <div className="flex flex-row items-center">
        <MapPinIcon className="h-6 w-6 mr-2" />
        <div className="text-sm">{headquarters}</div>
      </div>
      <div className="flex flex-col items-left">
        <div className="flex flex-row mb-1">
          <People className="h-6 w-6 mr-2" />
          <div className="text-sm text-left">{employees}</div>
        </div>
      </div>
    </div>
  );
};

const CompanyOverviewComponent = ({
  legalName,
  companyWebsite,
}: CompanyOverviewComponentProps) => {
  const { orgId } = useParams();
  if (!orgId) {
    throw new Error("orgId is required");
  }

  const { data, isLoading } = useCompanyOverviewQuery(
    orgId,
    legalName,
    companyWebsite,
  );

  return (
    <div className="flex flex-row space-x-4 w-full h-full">
      {isLoading ? (
        <SkeletonOverview />
      ) : data ? (
        <CompanyOverviewContents {...data} />
      ) : null}
    </div>
  );
};

export default CompanyOverviewComponent;
