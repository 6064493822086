import { Button } from "@/components/ui/Button";
import { List } from "@/components/ui/List";
import { Text } from "@/components/ui/Text";
import { TextField } from "@/components/ui/TextField";
import { useCompanyWebsites } from "@/features/companies/hooks/useCompanyWebsites";
import { useCreateCompanyStore } from "@/features/companies/store/useCreateCompanyStore";
import React, { useEffect } from "react";
import { Form } from "react-aria-components";

const TabWebsite = () => {
  const {
    nextTab,
    previousTab,
    companyName,
    website,
    setWebsite,
    setWebsiteOptions,
    websiteOptions,
  } = useCreateCompanyStore();

  const companyWebsites = useCompanyWebsites(companyName);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    nextTab();
  };

  useEffect(() => {
    if (companyWebsites.data) {
      setWebsiteOptions(companyWebsites.data);
      setWebsite(new URL(companyWebsites.data[0].companyWebsite).origin);
    }
  }, [companyWebsites.data]);

  const handleSelectWebsite = (item: string | null) => {
    if (item) {
      setWebsite(new URL(item).origin);
    }
  };

  const handleSkip = () => {
    setWebsite("");
    nextTab();
  };

  return (
    <div className="flex flex-col gap-3">
      <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <TextField
            label="Website"
            value={website}
            onChange={setWebsite}
            autoFocus
          />
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1.5">
              <Text type="label" color="label" strong className="pl-3">
                Suggestions
              </Text>
              <div className="border rounded-layout h-fit overflow-scroll max-h-[200px]">
                <List
                  onSelection={handleSelectWebsite}
                  items={websiteOptions.map((w) => ({
                    id: w.companyWebsite,
                    label: new URL(w.companyWebsite).origin,
                    avatar: `https://www.google.com/s2/favicons?domain=${new URL(w.companyWebsite).origin}&sz=128`,
                  }))}
                  emptyText="No suggestions found."
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-1.5">
          <Button
            variant="secondary"
            iconLeft="chevronLeft"
            onClick={previousTab}
          >
            Back
          </Button>
          <div className="flex-1" />
          <Button variant="secondary" onClick={handleSkip}>
            Skip
          </Button>
          <Button type="submit" iconRight="chevronRight" onClick={() => {}}>
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default TabWebsite;
