import { useAuth, defaultRetryFunc } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import { readCompanyUrls } from "../api/readCompanyUrls";

export const useCompanyUrlsQuery = (
  orgId: string,
  legalName: string,
  id: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "companyUrls", legalName, id],
    queryFn: () => readCompanyUrls(user, orgId, legalName, id),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};
