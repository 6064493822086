import React, { useEffect, useMemo, useState } from "react";
import styles from "./Filter.styles";
import {
  Button as AriaButton,
  Dialog,
  DialogTrigger,
  Popover,
} from "react-aria-components";
import { Checkbox } from "@/components/ui/Checkbox";
import { Divider } from "@/components/ui/Divider";
import { Button } from "@/components/ui/Button";
import { Icon } from "@/components/ui/Icon";

export type FilterOption =
  | FilterOptionCheckbox
  | FilterOptionDivider
  | FilterOptionLabel;

export interface FilterProps {
  options: FilterOption[];
  onSave: (options: FilterOption[]) => void;
  buttonLabel: string;
  saveLabel?: string;
  popoverPlacement?: "left" | "right";
  maxLength?: number;
  maxHeight?: number;
  renderLabelOnActive?: boolean;
}

export const Filter = ({
  options,
  popoverPlacement = "right",
  saveLabel = "Save",
  buttonLabel,
  onSave,
  maxLength = 15,
  maxHeight = 300,
  renderLabelOnActive = true,
}: FilterProps) => {
  const [storedOptions, setStoredOptions] = useState(options);
  const [openState, setOpenState] = useState(false);
  const { button, popover, dialog, filterLabel, concatenatedLabel } = styles({
    isOpen: openState,
  });

  useEffect(() => {
    setStoredOptions(options);
  }, [options]);

  const handleOptionChange = (updatedOption: FilterOption, index: number) => {
    setStoredOptions(
      storedOptions.map((option, currentIndex) =>
        currentIndex === index ? updatedOption : option,
      ),
    );
  };

  const handleOpenChange = (isOpen: boolean) => {
    setOpenState(isOpen);
    if (!isOpen) {
      setStoredOptions(options);
    }
  };

  const handleSave = (close: () => void) => {
    onSave(storedOptions);
    close();
  };

  const concatenatedLabelOptions = useMemo(() => {
    let result = "";
    let totalLength = 0;
    const visibleOptions: string[] = [];
    const savedOptions = options
      .filter((option) => option.type === "checkbox" && option.selected)
      .map((option) => (option as FilterOptionCheckbox).label);

    const suffixTemplate = (remaining: number, hasVisibleOptions: boolean) =>
      hasVisibleOptions ? ` + ${remaining} more` : ` + ${remaining} selected`;

    for (let i = 0; i < savedOptions.length; i++) {
      const option = savedOptions[i];

      if (totalLength + option.length <= maxLength) {
        visibleOptions.push(option);
        totalLength += option.length + (i > 0 ? 2 : 0);
      } else {
        break;
      }
    }

    const remainingCount = savedOptions.length - visibleOptions.length;

    result = visibleOptions.join(", ");
    if (remainingCount > 0) {
      result += suffixTemplate(remainingCount, visibleOptions.length > 0);
    }

    return result.toUpperCase();
  }, [options]);

  return (
    <DialogTrigger onOpenChange={handleOpenChange}>
      <>
        <AriaButton className={button()}>
          {renderLabelOnActive && buttonLabel.toUpperCase()}
          {concatenatedLabelOptions.length > 0 && (
            <>
              {renderLabelOnActive && <Divider direction="vertical" />}
              <span className={concatenatedLabel()}>
                {concatenatedLabelOptions}
              </span>
            </>
          )}
          <Icon type="chevronDown" size="xs" />
        </AriaButton>
        <Popover
          className={({ isEntering, isExiting }) =>
            popover({ isEntering, isExiting })
          }
          placement={`bottom ${popoverPlacement}`}
          offset={4}
          maxHeight={maxHeight}
        >
          <Dialog className={dialog()}>
            {({ close }) => (
              <>
                {storedOptions.map((option, index) => {
                  switch (option.type) {
                    case "label":
                      return (
                        <span className={filterLabel()}>
                          {option.label.toUpperCase()}
                        </span>
                      );
                    case "divider":
                      return <Divider key={index} />;
                    case "checkbox":
                      return (
                        <Checkbox
                          key={index}
                          label={option.label}
                          selected={option.selected}
                          onChange={(selected) =>
                            handleOptionChange({ ...option, selected }, index)
                          }
                        />
                      );
                  }
                })}
                <Divider />
                <Button size="sm" onClick={() => handleSave(close)}>
                  {saveLabel}
                </Button>
              </>
            )}
          </Dialog>
        </Popover>
      </>
    </DialogTrigger>
  );
};

// Filter Types
export type FilterOptionLabel = {
  type: "label";
  label: string;
};
export type FilterOptionDivider = {
  type: "divider";
};
export type FilterOptionCheckbox = {
  type: "checkbox";
  label: string;
  disabled?: boolean;
  selected: boolean;
};
