import NavigationIconCard from "../base/NavigationIconCard";
import { useParams } from "react-router-dom";
import TabLayout from "../TabLayout";
import { useDealQuery } from "../../hooks/deals";
import {
  ChatBubbleLeftRightIcon,
  DocumentPlusIcon,
  DocumentMagnifyingGlassIcon,
  FolderIcon,
  GlobeAltIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  TableCellsIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { usePostHog } from "posthog-js/react";

type URLParams = {
  orgId: string;
  dealId: string;
};

export default function Applications() {
  const { orgId, dealId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const posthog = usePostHog();

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: null,
        },
      ]}
    >
      <div className="h-full overflow-auto p-4">
        {/* <div className="grid grid-cols-[repeat(auto-fit,_minmax(min(100%,28rem),_1fr))] gap-x-4 gap-y-4"> */}
        <div className="flex flex-row flex-wrap">
          <NavigationIconCard
            name="Manage Files"
            icon={FolderIcon}
            description="Browse and manage deal data."
            href={`/orgs/${orgId}/deals/${dealId}/files`}
          />
          <NavigationIconCard
            name="Registry Search"
            icon={DocumentMagnifyingGlassIcon}
            description="Download data from local registries such as Companies House and Bundesanzeiger."
            href={`/orgs/${orgId}/deals/${dealId}/registry-search-jobs`}
          />
          <NavigationIconCard
            name="Company Website"
            icon={GlobeAltIcon}
            description="Download data from websites."
            href={`/orgs/${orgId}/deals/${dealId}/websites`}
          />
          <NavigationIconCard
            name="Question Threads"
            icon={QuestionMarkCircleIcon}
            description="Browse and run templated questions across your deal data."
            href={`/orgs/${orgId}/deals/${dealId}/question-threads`}
          />
          <NavigationIconCard
            name="Chat Assistant"
            icon={ChatBubbleLeftRightIcon}
            description="Ask adhoc questions about your deal data."
            href={`/orgs/${orgId}/deals/${dealId}/chat-threads`}
          />
          <NavigationIconCard
            name="Table Aggregation"
            icon={TableCellsIcon}
            description="Aggregate tables across multiple files."
            href={`/orgs/${orgId}/deals/${dealId}/table-aggregations`}
          />
          <NavigationIconCard
            name="User Management"
            icon={UsersIcon}
            description="Manage which users have access to this deal."
            href={`/orgs/${orgId}/deals/${dealId}/users`}
          />
          {(posthog.isFeatureEnabled("search") ||
            process.env.NODE_ENV === "development") && (
            <NavigationIconCard
              name="Search"
              icon={MagnifyingGlassIcon}
              description="Search across all your deal data."
              href={`/orgs/${orgId}/deals/${dealId}/search`}
            />
          )}
          <NavigationIconCard
            name="Data Extractions"
            icon={DocumentPlusIcon}
            description="Create and manage Data Extractions."
            href={`/orgs/${orgId}/deals/${dealId}/company-profiles`}
          />
        </div>
      </div>
    </TabLayout>
  );
}
