import { withVariants } from "@/utils/withVariants";

const styles = withVariants({
  slots: {
    button:
      "clickable pressable outline-none border text-size-caption text-label font-strong h-6 pl-2.5 px-2 py-1 rounded-pill bg flex gap-1.5 items-center justify-center",
    popover:
      "border rounded-element bg outline-none max-w-xs p-3 w-fit overflow-auto",
    dialog: "outline-none flex flex-col gap-2",
    filterLabel: "text-label text-size-caption font-strong",
    concatenatedLabel: "text-primary",
  },
  variants: {
    isOpen: {
      true: {
        button: "border-primary text-primary bg !brightness-100",
      },
    },
    isEntering: {
      true: {
        popover: "popover-in",
      },
    },
    isExiting: {
      true: {
        popover: "popover-out",
      },
    },
  },
});

export default styles;
