import type React from "react";
import styles from "./Checkbox.styles";
import { Checkbox as AriaCheckbox } from "react-aria-components";
import { motion } from "framer-motion";

export type CheckboxSize = "sm" | "md";

export interface CheckboxProps {
  label?: string;
  size?: CheckboxSize;
  disabled?: boolean;
  selected?: boolean;
  onChange?: (selected: boolean) => void;
}

export const Checkbox = ({
  label,
  size = "md",
  disabled,
  selected,
  onChange,
}: CheckboxProps) => {
  const { wrapper, checkbox, labelStyle, check } = styles({ size, disabled });
  return (
    <AriaCheckbox
      className={wrapper()}
      isDisabled={disabled}
      onChange={onChange}
      isSelected={selected}
      defaultSelected={selected}
    >
      {({ isSelected }) => (
        <>
          <div className={checkbox({ isSelected })}>
            <svg
              viewBox="0 0 18 18"
              aria-hidden="true"
              className={check({ isSelected })}
            >
              <motion.polyline
                points="2 10 7 14 16 4"
                animate={{ pathLength: isSelected ? 1 : 0 }}
                strokeLinecap="round"
              />
            </svg>
          </div>
          {label && <span className={labelStyle({ isSelected })}>{label}</span>}
        </>
      )}
    </AriaCheckbox>
  );
};
