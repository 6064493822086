import { withVariants } from "@/utils/withVariants";

const styles = withVariants({
  slots: {
    base: "w-full flex flex-col gap-1.5",
    labelStyle:
      "flex pl-3 items-center gap-1 text-label text-size-label font-strong",
    inputWrapper: "relative h-10",
    inputStyle:
      "focus-input no-cancel h-full outline-none border-base text-size-body text rounded pr-7 pl-9 placeholder:text-subdued w-full",
    icon: "flex items-center justify-center h-6 w-6 bg-primary rounded-badge text-label absolute top-1/2 transform -translate-y-1/2 left-2",
    cancelStyle:
      "clickable text-subdued absolute top-1/2 transform -translate-y-1/2 right-2",
    popoverStyle: "w-[var(--trigger-width)]",
    listStyle:
      "bg border rounded-layout overflow-scroll max-h-[400px] shadow-popover",
    itemStyle:
      "focus-none focus-visible:brightness-95 px-3 bg py-2 text-size-label text border-b last:border-none clickable",
    listLabel: "",
    listDescription: "text-size-label text-label",
    loadingStyle: "w-full flex items-center gap-1.5 px-3 py-4",
  },

  variants: {
    focused: {
      true: {
        labelStyle: "text-primary",
        icon: "bg-primary-selected text-primary",
      },
    },
    isDisabled: {
      true: {
        base: "disabled",
      },
    },
    isSelected: {
      true: {
        itemStyle:
          "!bg-primary border-b-primary focus-visible:border-b-primary",
      },
    },
  },
});

export default styles;
