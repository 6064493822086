import { withVariants } from "@/utils/withVariants";

const styles = withVariants({
  slots: {
    wrapper:
      "clickable pressable flex gap-1.5 rounded-badge focus-visible:outline",
    checkbox:
      "transition flex justify-center items-center shrink-0 bg text-oncolor rounded-badge border border-strong",
    check: "stroke-current fill-none stroke-2",
    labelStyle: "text-label",
  },
  variants: {
    isSelected: {
      true: {
        checkbox: "bg-primary-inverse border-primary",
      },
    },
    size: {
      sm: {
        checkbox: "w-3 h-3 mt-px",
        check: "w-2.5 h-2.5",
        labelStyle: "text-size-caption",
      },
      md: {
        checkbox: "w-4 h-4 mt-0.5",
        check: "w-3 h-3",
        labelStyle: "text-size-label",
      },
    },
    disabled: {
      true: {
        wrapper: "disabled",
      },
    },
  },
});

export default styles;
