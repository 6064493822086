import type React from "react";
import { listStyles, listItemStyles } from "./List.styles";
import {
  ListBox,
  ListBoxItem,
  Selection,
  Text as AriaText,
} from "react-aria-components";
import { useMemo } from "react";
import { Icon, IconType } from "@/components/ui/Icon";
import { Text } from "@/components/ui/Text";

export type ListItem = {
  id: string;
  label: string;
  description?: string;
  icon?: IconType;
  avatar?: string;
  selectable?: boolean;
};

export interface ListProps {
  label?: string;
  items: ListItem[];
  selectedId?: string | null;
  onSelection?: (id: string | null) => void;
  emptyText?: string;
  /** Used for dynamically updating lists, like search results */
  useIndexKeys?: boolean;
}

export const List = ({
  label = "list",
  items,
  onSelection,
  selectedId,
  emptyText = "No items",
  useIndexKeys,
}: ListProps) => {
  const onChange = (newSelected: Selection) => {
    const key = [...newSelected][0] || null;
    if (onSelection) {
      onSelection(key as string);
    }
  };

  const selectedKeys = useMemo(() => {
    return selectedId
      ? [
          useIndexKeys
            ? `item-${items.findIndex((item) => item.id === selectedId)}`
            : selectedId,
        ]
      : [];
  }, [selectedId]);

  const { base } = listStyles();

  return (
    <ListBox
      aria-label={label}
      className={base()}
      selectionMode={onSelection ? "single" : "none"}
      selectedKeys={selectedKeys}
      onSelectionChange={onChange}
      renderEmptyState={() => (
        <span className="flex items-center p-3">
          <Text type="label" color="label">
            {emptyText}
          </Text>
        </span>
      )}
    >
      {items.map(({ ...props }, index) => {
        const key = useIndexKeys ? `item-${index}` : props.id;
        return <ListItem key={key} {...props} id={key} selectable={false} />;
      })}
    </ListBox>
  );
};

const ListItem = ({
  id,
  label,
  description,
  icon,
  avatar,
  selectable,
}: ListItem) => {
  const {
    baseStyle,
    contentStyle,
    descriptionStyle,
    iconStyle,
    arrowStyle,
    avatarStyle,
  } = listItemStyles();
  return (
    <ListBoxItem
      id={id}
      textValue={label}
      className={({ isSelected, isFocused }) =>
        baseStyle({ isSelected, isFocused, selectable })
      }
    >
      {({ isSelected }) => (
        <>
          {avatar && (
            <img src={avatar} alt="avatar" className={avatarStyle()} />
          )}
          {icon && (
            <span className={iconStyle({ isSelected })}>
              <Icon type={icon} size="md" />
            </span>
          )}
          <div className={contentStyle()}>
            <AriaText slot="label">{label}</AriaText>
            {description && (
              <AriaText slot="description" className={descriptionStyle()}>
                {description}
              </AriaText>
            )}
          </div>
          <span className={arrowStyle({ isSelected })}>
            <Icon type="chevronRight" size="sm" />
          </span>
        </>
      )}
    </ListBoxItem>
  );
};
