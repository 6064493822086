import React from "react";
import { useEmployeeSentimentQuery } from "../hooks/employeeSeniment";
import { useParams } from "react-router-dom";

export interface CompanySentimentData {
  legalName: string;
  companyWebsite: string;
}

const GeneralScore = ({
  size,
  generalScoreOverFive,
}: {
  size?: string;
  generalScoreOverFive: number;
}) => {
  const filledDots = Math.floor(generalScoreOverFive);
  const partialDot = generalScoreOverFive - filledDots;
  const remainingDots = 5 - filledDots - (partialDot > 0 ? 1 : 0);

  const getBgColor = (score: number) => {
    if (score >= 4) return "bg-green-500";
    if (score >= 2.5) return "bg-yellow-500";
    return "bg-red-500";
  };

  const getTextColor = (score: number) => {
    if (score >= 4) return "text-green-500";
    if (score >= 2.5) return "text-yellow-500";
    return "text-red-500";
  };

  const getDotSize = (size?: string | null) => {
    if (size && size === "sm") return "h-2 w-2";
    return "h-4 w-4";
  };

  const getTextSize = (size?: string | null) => {
    if (size && size === "sm") return "text-md";
    return "text-lg";
  };

  const dotColor = getBgColor(generalScoreOverFive);

  return (
    <div className="flex flex-row items-center">
      <div
        className={`font-bold ${getTextColor(generalScoreOverFive)} ${getTextSize(size)}`}
      >
        {generalScoreOverFive.toFixed(1)}
      </div>
      <div className="ml-2 flex flex-row flex-wrap">
        {[...Array(filledDots)].map((_, i) => (
          <div
            key={i}
            className={`rounded-full mr-1 ${dotColor} ${getDotSize(size)}`}
          />
        ))}
        {partialDot > 0 && (
          <div
            className={`h-4 w-4 rounded-full mr-1 ${dotColor} ${getDotSize(size)}`}
            style={{ opacity: Math.max(0.3, partialDot) }}
          />
        )}
        {[...Array(remainingDots)].map((_, i) => (
          <div
            key={i}
            className={`h-4 w-4 rounded-full bg-gray-300 mr-1 ${getDotSize(size)}`}
          />
        ))}
      </div>
    </div>
  );
};

interface ScoresData {
  generalScoreOverFive: number;
  wouldRecommendPercent: number;
  ceoApprovalPercent: number;
  compensationAndBenefitsScoreOverFive: number;
  workLifeBalanceScoreOverFive: number;
  careerOpportunitiesScoreOverFive: number;
  cultureAndValuesScoreOverFive: number;
  diversityAndInclusionScoreOverFive: number;
  seniorLeadershipScoreOverFive: number;
}

const Scores = ({ data }: { data: ScoresData }) => {
  return (
    <div className="flex flex-col space-y-1">
      <div className="flex flex-col items-left">
        <div className="text-sm">General Score</div>
        <GeneralScore generalScoreOverFive={data.generalScoreOverFive} />
      </div>
      <div className="flex flex-row py-1">
        <div className="flex flex-col items-left">
          <div className="text-sm">Would Recommend</div>
          <div className="text-lg font-semibold">
            {data.wouldRecommendPercent.toFixed(0)}%
          </div>
        </div>
        <hr className="w-0.5 h-full bg-gray-300 mx-2" />
        <div className="flex flex-col items-left">
          <div className="text-sm">CEO Approval</div>
          <div className="text-lg font-semibold">
            {data.ceoApprovalPercent.toFixed(0)}%
          </div>
        </div>
      </div>
      <div className="flex flex-col items-left">
        <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-4">
          <div className="w-full">
            <div className="text-xs">Compensation and Benefits</div>
            <div className="">
              <GeneralScore
                size={"sm"}
                generalScoreOverFive={data.compensationAndBenefitsScoreOverFive}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="text-xs">Work Life Balance</div>
            <div className="">
              <GeneralScore
                size={"sm"}
                generalScoreOverFive={data.workLifeBalanceScoreOverFive}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="text-xs">Career Opportunities</div>
            <div className="">
              <GeneralScore
                size={"sm"}
                generalScoreOverFive={data.careerOpportunitiesScoreOverFive}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="text-xs">Culture and Values</div>
            <div className="">
              <GeneralScore
                size={"sm"}
                generalScoreOverFive={data.cultureAndValuesScoreOverFive}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="text-xs">Diversity and Inclusion</div>
            <div className="">
              <GeneralScore
                size={"sm"}
                generalScoreOverFive={data.diversityAndInclusionScoreOverFive}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="text-xs">Senior Leadership</div>
            <div className="">
              <GeneralScore
                size={"sm"}
                generalScoreOverFive={data.seniorLeadershipScoreOverFive}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CompanySentimentComponent = ({
  legalName,
  companyWebsite,
}: CompanySentimentData) => {
  const { orgId } = useParams();

  if (!orgId) {
    throw new Error("No orgId found in URL");
  }

  const { data } = useEmployeeSentimentQuery(orgId, legalName, companyWebsite);

  return (
    <div className="content-left flex flex-col border rounded-lg p-4 space-y-3 h-full">
      <div className="font-bold text-lg">Employee Sentiment</div>
      {data && <Scores data={data} />}
    </div>
  );
};

export default CompanySentimentComponent;
// export company sentiment data interface
