import { withVariants } from "@/utils/withVariants";

const styles = withVariants({
  base: "stroke-current animate-spin overflow-visible",
  variants: {
    size: {
      xs: "h-icon-xs w-icon-xs",
      sm: "h-icon-sm w-icon-sm",
      md: "h-icon-md w-icon-md",
      lg: "h-icon-lg w-icon-lg",
    },
    variant: {
      default: "text-primary",
      oncolor: "text-oncolor",
    },
  },
});

export default styles;
