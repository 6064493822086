import { Icon } from "@/components/ui/Icon";
import { IconButton } from "@/components/ui/IconButton";
import { Text } from "@/components/ui/Text";
import React, { useRef, useState } from "react";

interface FileUploadProps {
  onFileUpload: (files: FileList) => void;
}

const FileUploadComponent: React.FC<FileUploadProps> = ({ onFileUpload }) => {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const droppedFiles = Array.from(e.dataTransfer.files);
      setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
      onFileUpload(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFiles = Array.from(e.target.files);
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      onFileUpload(e.target.files);
    }
  };

  const removeFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="rounded-layout content-left space-y-3 relative w-full content-left flex flex-col border p-3 p-4space-y-3 h-full">
      <div
        className="w-full flex flex-col justify-center items-center space-y-2"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragEnter}
        onDrop={handleDrop}
      >
        <div
          className={`transition bg gap-4 w-full ${files.length > 0 ? "h-40" : "h-80"} border border-dashed rounded-element flex flex-col justify-center items-center ${
            dragging ? "border-primary bg-secondary" : "border"
          }`}
        >
          <Icon size="hero" type="upload" withBG />
          <Text color="label">
            Drag and drop or{" "}
            <span
              className="text-primary clickable pressable"
              onClick={triggerFileInput}
            >
              browse files
            </span>{" "}
            to upload
          </Text>
          <input
            ref={fileInputRef}
            type="file"
            multiple
            className="hidden"
            onChange={handleFileSelect}
          />
        </div>
      </div>

      {files.length > 0 && (
        <div
          className="flex flex-col space-y-2 overflow-y-auto"
          style={{ maxHeight: "350px" }}
        >
          {files.map((file, index) => (
            <div
              key={index}
              className="border flex justify-between items-center px-3 py-2 bg-secondary rounded"
            >
              <Text type="label" color="label">
                {file.name}
              </Text>
              <IconButton
                variant="tertiary"
                onClick={() => removeFile(index)}
                icon="delete"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUploadComponent;
