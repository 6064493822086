import { withVariants } from "@/utils/withVariants";

const styles = withVariants({
  slots: {
    base: "w-full flex flex-col gap-1.5",
    labelStyle:
      "flex pl-3 items-center gap-1 text-label text-size-label font-strong",
    inputWrapper: "relative h-10",
    inputStyle:
      "focus-input no-cancel h-full outline-none border-base text-size-body text rounded px-3 placeholder:text-subdued w-full",
    descriptionStyle: "text-size-caption text-label",
  },

  variants: {
    focused: {
      true: {
        labelStyle: "text-primary",
        icon: "bg-primary-selected text-primary",
        descriptionStyle: "text-primary",
      },
    },
    isDisabled: {
      true: {
        base: "disabled",
      },
    },
  },
});

export default styles;
