import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";

export interface RegistryDocument {
  documentName: string;
  documentId: string;
  effectiveDate: string | null;
  registryIdentifier: string;
}

export const listRegistryDocuments = async (
  user: User,
  orgId: string,
  legalName: string,
  countryCode: string,
  id: string,
): Promise<RegistryDocument[]> => {
  return await resolveFetch<RegistryDocument[]>(
    fetch(
      `${baseURL}/orgs/${orgId}/companies-search/registry-documents?legal_name=${encodeURIComponent(legalName)}&country_code=${countryCode}&id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
    true,
  );
};
