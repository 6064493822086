import React, { useState, useEffect, useRef } from "react";
import { useCompanyFinancialsQuery } from "../hooks/companyFinancials";
import Skeleton from "../../base/Skeleton";
import { useParams } from "react-router-dom";
import { CompanyFinancials } from "../api/readCompanyFinancials";

interface CompanyFinancialsComponentProps {
  id: string;
}

interface TableProps {
  data: string[][];
}

const Table = ({ data }: TableProps) => {
  const [headers, setHeaders] = useState<string[]>([]);
  const [rows, setRows] = useState<string[][]>([]);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data.length > 0) {
      setHeaders(data[0]);
      setRows(data.slice(1));
    }
  }, [data]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollLeft = tableRef.current.scrollWidth;
    }
  }, [headers, rows]);

  return (
    <div className="flex items-top justify-center w-full">
      <div className="overflow-x-auto w-full" ref={tableRef}>
        <table className="w-full table-auto">
          <thead>
            <tr className="border-b border-gray-200">
              {headers.map((header, index) => (
                <th
                  key={index}
                  className={`py-3 px-6 font-normal text-sm text-gray-400 ${index === 0 ? "sticky left-0 bg-white text-left pl-0" : "text-right"}`}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell: string, cellIndex: number) => (
                  <td
                    key={cellIndex}
                    className={`py-3 px-6  text-sm ${cellIndex === 0 ? "sticky left-0 bg-white pl-0" : "text-right"}`}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const SkeletonTable = () => {
  return (
    <table className="w-full h-full">
      <thead>
        <tr className="border-b border-gray-200">
          <th className="py-3 px-6 pl-0 text-right flex elements-right">
            <Skeleton width="80%" height="2rem" />
          </th>
        </tr>
      </thead>
      <tbody>
        {[...Array(4)].map((_, index) => (
          <tr key={index} className="border-b border-gray-200">
            <td className="py-3 px-6 pl-0 text-left">
              <Skeleton width="90%" height="2rem" />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const CompanyFinancialsComponent = ({
  id,
}: CompanyFinancialsComponentProps) => {
  const { orgId } = useParams();

  if (!orgId) {
    throw new Error("No orgId found in URL");
  }

  const { data, isLoading } = useCompanyFinancialsQuery(orgId, id);

  const [financials, setFinancials] = useState<CompanyFinancials | null>(null);

  const tableFromFinancials = (financials: CompanyFinancials): string[][] => {
    if (!financials || financials.entries.length == 0) {
      return [];
    }

    const headers = [`${financials.currencyUnit}m`].concat(financials.years);
    const rows = financials.entries.map((entry) => [
      entry.label,
      ...entry.data,
    ]);

    return [headers, ...rows];
  };

  useEffect(() => {
    if (data) {
      setFinancials(data as unknown as CompanyFinancials);
    }
  }, [data?.entries]);

  return (
    <div className="content-left flex flex-col border rounded-lg p-4 space-y-3 h-full">
      <div className="font-bold text-lg">Financials</div>
      {isLoading && <SkeletonTable />}
      {financials && financials.entries.length > 0 && (
        <Table data={tableFromFinancials(financials)} />
      )}
      {financials && (
        <div className="text-sm text-gray-500">
          &#9432; This preview may be incomplete or missing sometimes. Once you
          save this deal you will have access to all the financials extracted
          from the public files you select.
        </div>
      )}
    </div>
  );
};

export default CompanyFinancialsComponent;
