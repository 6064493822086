import type React from "react";
import styles from "./Divider.styles";

export type DividerVariant = "default" | "padded";

export interface DividerProps {
  variant?: DividerVariant;
  direction?: "horizontal" | "vertical";
  length?: number;
}

export const Divider = ({
  variant = "default",
  direction = "horizontal",
  length,
}: DividerProps) => {
  const { wrapper, border } = styles({ variant, direction });

  const lineLength = () => {
    if (direction === "horizontal") {
      return { width: `${length}px` };
    } else {
      return { height: `${length}px` };
    }
  };

  return (
    <div role="separator" aria-orientation={direction} className={wrapper()}>
      <div style={length ? lineLength() : {}} className={border()} />
    </div>
  );
};
