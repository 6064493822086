import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";

export interface EmployeeSentiment {
  wouldRecommendPercent: number;
  ceoApprovalPercent: number;
  generalScoreOverFive: number;
  compensationAndBenefitsScoreOverFive: number;
  workLifeBalanceScoreOverFive: number;
  careerOpportunitiesScoreOverFive: number;
  cultureAndValuesScoreOverFive: number;
  diversityAndInclusionScoreOverFive: number;
  seniorLeadershipScoreOverFive: number;
}

export const readEmployeeSentiment = async (
  user: User,
  orgId: string,
  legalName: string,
  companyWebsite: string,
): Promise<EmployeeSentiment> => {
  return await resolveFetch<EmployeeSentiment>(
    fetch(
      `${baseURL}/orgs/${orgId}/companies-search/employee-sentiment?legal_name=${encodeURIComponent(legalName)}&company_website=${encodeURIComponent(companyWebsite)}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
    true,
  );
};
