import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";

export interface CompanyNameSuggestion {
  companyName: string;
  countryCode: string;
  id: string;
  graphId: string;
}

export const listCompanyNamesQuickSuggestions = async (
  user: User,
  orgId: string,
  query: string,
): Promise<CompanyNameSuggestion[]> => {
  return await resolveFetch<CompanyNameSuggestion[]>(
    fetch(
      `${baseURL}/orgs/${orgId}/companies-search/quick-suggestions?query=${encodeURIComponent(query)}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
    true,
  );
};

export const listCompanyNamesExtendedSuggestions = async (
  user: User,
  orgId: string,
  query: string,
): Promise<CompanyNameSuggestion[]> => {
  return await resolveFetch<CompanyNameSuggestion[]>(
    fetch(
      `${baseURL}/orgs/${orgId}/companies-search/extended-suggestions?query=${encodeURIComponent(query)}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
    true,
  );
};
