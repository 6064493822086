import React, { useState, useEffect, JSXElementConstructor } from "react";
import { Linkedin, Globe, CardList, PencilSquare } from "react-bootstrap-icons";
import Skeleton from "../../base/Skeleton";
import { useCompanyUrlsQuery } from "../hooks/companyUrls";
import { CompanyUrls } from "../api/readCompanyUrls";
import { useParams } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

interface TextWithLogoProps {
  text?: string | null;
  logo: JSXElementConstructor<{ size: number }>;
  url?: string | null;
  editableField: "text" | "url" | null;
  urlToTextFormatter?: (url: string) => string;
  onEditableFieldChange?: (text: string) => void;
}

const TextWithLogo = ({
  text,
  logo: Logo,
  url,
  editableField,
  urlToTextFormatter,
  onEditableFieldChange,
}: TextWithLogoProps) => {
  const [editMode, setEditMode] = useState(false);
  const [innerText, setInnerText] = useState(text || "");
  const [innerUrl, setInnerUrl] = useState(url || "");

  useEffect(() => {
    setInnerUrl(url || "");
  }, [url]);

  useEffect(() => {
    const currentUrl = innerUrl || "";
    const newText = urlToTextFormatter
      ? urlToTextFormatter(currentUrl)
      : currentUrl;
    setInnerText(newText);
  }, [innerUrl]);

  useEffect(() => {
    setInnerText(text || "");
  }, [text]);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editableField === "text") {
      setInnerText(e.target.value);
      if (onEditableFieldChange) {
        onEditableFieldChange(e.target.value);
      }
    } else {
      setInnerUrl(e.target.value);
      if (onEditableFieldChange) {
        onEditableFieldChange(e.target.value);
      }
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSave = () => {
    setEditMode(false);
  };

  return (
    <div className="w-56 flex flex-row items-center justify-center h-10">
      <div className="flex justify-end">
        <Logo size={20} />
      </div>

      {editMode ? (
        <div className="flex items-baseline ml-2">
          <input
            className="border border-gray-300 rounded-lg p-1"
            value={
              editableField && editableField === "text" ? innerText : innerUrl
            }
            onChange={handleTextChange}
            onBlur={handleSave}
            autoFocus
          />
        </div>
      ) : innerText ? (
        url ? (
          <div className="flex items-baseline">
            <a
              href={innerUrl}
              target="_blank"
              rel="noreferrer"
              className="flex justify-start items-baseline ml-2 hover:underline text-blue-500"
            >
              {innerText}
            </a>
            {editableField === "url" && (
              <PencilSquare
                className="ml-2 cursor-pointer text-gray-500 hover:text-gray-700"
                onClick={handleEditClick}
              />
            )}
          </div>
        ) : (
          <div className="flex items-baseline ml-2">
            {innerText}
            {editableField === "text" && (
              <PencilSquare
                className="ml-2 cursor-pointer text-gray-500 hover:text-gray-700"
                onClick={handleEditClick}
              />
            )}
          </div>
        )
      ) : (
        <div className="items-baseline ml-2 w-32">
          <Skeleton width="100%" height="20px" />
        </div>
      )}
    </div>
  );
};

interface CompanyUrlsComponentProps {
  legalName: string;
  id: string;
  updateCompanyUrls: (companyUrls: CompanyUrls) => void;
  approveDownload: () => void;
}

const CompanyUrlsComponent = ({
  legalName: inputLegalName,
  id,
  updateCompanyUrls,
  approveDownload,
}: CompanyUrlsComponentProps) => {
  const { orgId } = useParams();
  if (!orgId) {
    throw new Error("orgId is required");
  }

  const postHog = usePostHog();
  const { data, isLoading } = useCompanyUrlsQuery(orgId, inputLegalName, id);

  const [buttonVisible, setButtonVisible] = useState(true);
  const [additionalClasses, setAdditionalClasses] = useState("");
  const [linkedin, setLinkedin] = useState<string | null>(null);
  const [website, setWebsite] = useState<string | null>(null);
  const [legalName, setLegalName] = useState<string | null>(inputLegalName);
  const [editsAllowed, setEditsAllowed] = useState(true);

  useEffect(() => {
    setLegalName(inputLegalName);
  }, [inputLegalName]);

  useEffect(() => {
    if (data && data.length > 0) {
      setLinkedin(data[0].linkedinUrl);
      setWebsite(data[0].companyWebsite);
      setLegalName(data[0].legalName);
    }
  }, [data?.[0]?.linkedinUrl, data?.[0]?.companyWebsite, data?.[0]?.legalName]);

  useEffect(() => {
    if (isLoading) {
      setEditsAllowed(false);
      setLinkedin(null);
      setWebsite(null);
    }
  }, [isLoading]);

  useEffect(() => {
    setButtonVisible(true);
    setEditsAllowed(true);
    setAdditionalClasses("");
  }, [inputLegalName]);

  useEffect(() => {
    updateCompanyUrls({
      companyWebsite: website || "",
      linkedinUrl: linkedin || "",
      legalName: legalName || "",
      id: id,
    });
  }, [website, linkedin, legalName]);

  const domain = (url: string | null): string => {
    if (!url) {
      return "";
    }

    return new URL(url).hostname;
  };

  const linkedinName = (url: string | null): string => {
    if (!url) {
      return "";
    }

    const companyIndex = url.indexOf("company/");
    if (companyIndex === -1) {
      return "";
    }

    const companyUrl = url.slice(companyIndex + 8);
    const companyIdentifier = companyUrl.split("/")[0];

    return companyIdentifier;
  };

  const handleButtonClicked = () => {
    setButtonVisible(false);
    setEditsAllowed(false);
    approveDownload();
  };

  const handleOnAnimationEnd = () => {
    if (!buttonVisible) {
      setAdditionalClasses("hidden");
    } else {
      setAdditionalClasses("");
    }
  };

  return (
    <div className="content-left flex flex-col justify-evenly border rounded-lg mx-4 mt-6 mb-2 p-8 gap-8">
      <div className="w-full flex flex-row flex-wrap justify-evenly">
        <TextWithLogo
          logo={Globe}
          url={website}
          onEditableFieldChange={(text) => setWebsite(text)}
          editableField={editsAllowed ? "url" : null}
          urlToTextFormatter={domain}
        />
        {postHog.isFeatureEnabled("company_search_experimental_widgets") && (
          <TextWithLogo
            logo={Linkedin}
            url={linkedin}
            onEditableFieldChange={(text) => setLinkedin(text)}
            editableField={editsAllowed ? "url" : null}
            urlToTextFormatter={linkedinName}
          />
        )}
        <TextWithLogo text={legalName} logo={CardList} editableField={null} />
      </div>
      <div
        className={`${buttonVisible ? "" : "animate-retract"} ${additionalClasses}`}
        onAnimationEnd={handleOnAnimationEnd}
      >
        <hr className={"border-gray-200 w-full mb-8"} />
        <div className="flex flex-row items-start text-center">
          <p className="text-gray-700 text-sm text-left flex-1 pr-3">
            These are the data sources we automatically detected. They can be
            edited if any of them are incorrect. Once you are happy with the
            information, click the button to start.
          </p>
          <button
            className="bg-blue-500 h-10 text-sm text-white px-4 rounded hover:bg-blue-700"
            onClick={handleButtonClicked}
          >
            Gather Information
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompanyUrlsComponent;
