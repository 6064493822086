import { useAuth, defaultRetryFunc } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import {
  listCompanyNamesExtendedSuggestions,
  listCompanyNamesQuickSuggestions,
} from "../api/listCompanyNameSuggestions";

export const useQuickCompanyNamesSuggestionsQuery = (
  orgId: string,
  query: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "companyNamesQuickSuggestions", query],
    queryFn: () => listCompanyNamesQuickSuggestions(user, orgId, query),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useExtendedCompanyNamesSuggestionsQuery = (
  orgId: string,
  query: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "companyNamesExtendedSuggestions", query],
    queryFn: () => listCompanyNamesExtendedSuggestions(user, orgId, query),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};
