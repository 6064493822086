import React, { useEffect, useMemo, useState } from "react";
import { Breadcrumbs } from "@/components/ui/Breadcrumbs";
import { Button } from "@/components/ui/Button";
import { EditableHeading } from "@/components/ui/EditableHeading";
import { Tabs } from "@/components/ui/Tabs";
import { useCreateCompanyStore } from "@/features/companies/store/useCreateCompanyStore";
import { useNavigate, useParams } from "react-router-dom";
import { Tags } from "@/components/ui/Tags";
import { Divider } from "@/components/ui/Divider";

// Tabs
import TabAliases from "@/features/companies/components/TabAliases";
import TabCompany from "@/features/companies/components/TabCompany";
import TabFiles from "@/features/companies/components/TabFiles";
import TabRegistrySearch from "@/features/companies/components/TabRegistrySearch";
import TabWebsite from "@/features/companies/components/TabWebsite";
import { useCreateDealMutation } from "@/hooks/deals";
import UploadFilesPixel from "@/components/DealsTab/subcomponents/UploadFilesPixel";
import { useRegistrySearchStore } from "@/features/registrySearch/store/useRegistrySearchStore";

const tabSections = [
  "COMPANY",
  "REGISTRY SEARCH",
  "WEBSITE",
  "NAME ALIASES",
  "FILES",
];

export const CreateCompany = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const {
    reset,
    tabIndex,
    tabFalloff,
    setTabIndex,
    dealName,
    setDealName,
    companyName,
    website,
    aliases,
    files,
    legalEntity,
    registryDocuments,
    setDealId,
    dealId,
    companySuggestion,
  } = useCreateCompanyStore();
  const registrySearchStore = useRegistrySearchStore();

  const mutation = useCreateDealMutation(orgId as string);
  const [filesUploadCompleted, setFilesUploadCompleted] = useState(false);

  const finalTags: string[] = useMemo(() => {
    const tags: string[] = [];
    if (companyName) tags.push(companyName);
    if (legalEntity) tags.push(legalEntity.name);
    if (registryDocuments.length > 0)
      tags.push(`${registryDocuments.length} Documents`);
    if (website) tags.push(new URL(website).hostname);
    if (aliases.length > 0) tags.push(`${aliases.length} Aliases`);
    if (files.length > 0) tags.push(`${files.length} Files`);
    return tags;
  }, [companyName, website, aliases, files, legalEntity, registryDocuments]);

  const handleCreateDeal = () => {
    mutation.mutate(
      {
        name: dealName,
        companyName: companyName,
        companyAliases: aliases.map((alias) => alias.label),
        website: website,
        companyIdInRegistry: legalEntity?.id || "",
        registryFiles: registryDocuments.map((doc) => ({
          id: doc.documentId,
          registryIdentifier: doc.registryIdentifier,
        })),
        countryCode: companySuggestion?.countryCode || "",
      },
      {
        onSuccess: (data) => {
          setDealId(data.id);
        },
      },
    );
  };

  const handleCancel = () => {
    navigate(`/orgs/${orgId}/deals`);
  };

  useEffect(() => {
    return () => {
      registrySearchStore.reset();
      reset();
    };
  }, []);

  useEffect(() => {
    if (dealId) {
      if (files.length === 0 || filesUploadCompleted) {
        registrySearchStore.reset();
        reset();
        navigate(`/orgs/${orgId}/deals/${dealId}`);
      }
    }
  }, [dealId, filesUploadCompleted]);

  return (
    <>
      <div className="bg min-h-screen">
        <div className="flex items-center px-4 h-12 border-b gap-1.5">
          <div className="pl-3 w-full">
            <Breadcrumbs
              crumbs={[
                { label: "Deals", to: `/orgs/${orgId}/deals` },
                { label: "Create", to: "null" },
              ]}
            />
          </div>
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateDeal} disabled={!companyName}>
            Create Deal
          </Button>
        </div>
        <div className="flex flex-col px-4 py-6 pb-24 gap-4">
          <div className="pl-3 pb-3 border-b flex gap-4 items-center">
            <EditableHeading value={dealName} onChange={setDealName} />
            {finalTags.length > 0 && (
              <>
                <Divider direction="vertical" length={20} />
                <Tags
                  tags={finalTags.map((tag) => ({ label: tag, id: tag }))}
                />
              </>
            )}
          </div>
          <Tabs
            tabs={tabSections.map((section, index) => ({
              label: section,
              disabled: index > tabFalloff,
            }))}
            selectedTab={tabIndex}
            onSelectTab={setTabIndex}
          >
            {tabSections.map((section) => (
              <div key={section} className="pt-4 flex flex-col gap-6">
                {section === "COMPANY" && <TabCompany />}
                {section === "REGISTRY SEARCH" && <TabRegistrySearch />}
                {section === "WEBSITE" && <TabWebsite />}
                {section === "NAME ALIASES" && <TabAliases />}
                {section === "FILES" && (
                  <TabFiles onCreateDeal={handleCreateDeal} />
                )}
              </div>
            ))}
          </Tabs>
        </div>
      </div>
      {dealId && files && files.length > 0 && (
        <UploadFilesPixel
          orgId={orgId || ""}
          dealId={dealId}
          files={files}
          onUploadComplete={() => setFilesUploadCompleted(true)}
        />
      )}
    </>
  );
};
