import {
  getCompanySuggestionsExtended,
  getCompanySuggestionsQuick,
} from "@/features/companies/api/companySuggestions";
import { useAuth, defaultRetryFunc } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const useCompanySuggestionsQuick = (query: string) => {
  const { orgId } = useParams();
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "companyNamesQuickSuggestions", query],
    queryFn: () => getCompanySuggestionsQuick(user, orgId || "", query),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useComapanySuggestionsExtended = (query: string) => {
  const { orgId } = useParams();
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "companyNamesExtendedSuggestions", query],
    queryFn: () => getCompanySuggestionsExtended(user, orgId || "", query),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};
