import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";

export interface CompanyFinancialsEntry {
  label: string;
  unit: string;
  data: string[];
}

export interface CompanyFinancials {
  currencyUnit: string;
  years: string[];
  entries: CompanyFinancialsEntry[];
}

export const readCompanyFinancials = async (
  user: User,
  orgId: string,
  id: string,
): Promise<CompanyFinancials> => {
  return await resolveFetch<CompanyFinancials>(
    fetch(`${baseURL}/orgs/${orgId}/companies-search/financials?id=${id}`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};
