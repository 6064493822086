import type React from "react";
import { Button as AriaButton, type PressEvent } from "react-aria-components";
import { Icon, IconType } from "@/components/ui/Icon";
import { useMemo } from "react";
import styles from "./Button.styles";
import { Spinner } from "@/components/ui/Spinner";

export type ButtonType = "button" | "submit" | "reset";
export type ButtonVariant = "primary" | "secondary" | "tertiary" | "danger";
export type ButtonSize = "sm" | "md" | "lg";

export interface ButtonProps {
  children: React.ReactNode;
  /**
   * 	Handler that is called when the press is released over the target.
   */
  onClick: (e: PressEvent) => void;
  /**
   * The behavior of the button when used in an HTML form.
   */
  type?: ButtonType;
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  loading?: boolean;
  /**
   * The value associated with the button's name when it's submitted with the form data.
   */
  value?: string;
  iconLeft?: IconType;
  iconRight?: IconType;
  className?: string;
}

export const Button = ({
  children,
  onClick,
  type = "button",
  variant = "primary",
  size = "md",
  disabled,
  loading,
  value,
  iconLeft,
  iconRight,
  className,
}: ButtonProps) => {
  const iconSize = useMemo(() => {
    switch (size) {
      case "sm":
        return "xs";
      case "md":
        return "sm";
      case "lg":
        return "md";
    }
  }, [size]);

  const spinnerVariant = useMemo(() => {
    switch (variant) {
      case "primary":
      case "danger":
        return "oncolor";
      case "secondary":
      case "tertiary":
        return "default";
    }
  }, [variant]);

  return (
    <AriaButton
      className={({ isDisabled, isPending }) =>
        `${styles({ variant, size, isPending, isDisabled })} ${className}`.trim()
      }
      onPress={onClick}
      type={type}
      isDisabled={disabled}
      isPending={loading}
      value={value}
    >
      {({ isPending }) => (
        <>
          {iconLeft && <Icon type={iconLeft} size={iconSize} />}
          {children}
          {iconRight && <Icon type={iconRight} size={iconSize} />}
          {isPending && (
            <span className="absolute">
              <Spinner variant={spinnerVariant} size={iconSize} />
            </span>
          )}
        </>
      )}
    </AriaButton>
  );
};
