import { withVariants } from "@/utils/withVariants";

const styles = withVariants({
  base: "",
  variants: {
    type: {
      subheading: "text-size-subheading",
      body: "text-size-body",
      label: "text-size-label",
      caption: "text-size-caption",
    },
    truncated: {
      true: "whitespace-nowrap overflow-hidden text-ellipsis",
    },
    strong: {
      true: "font-strong",
    },
    color: {
      default: "text",
      label: "text-label",
      subdued: "text-subdued",
      primary: "text-primary",
    },
    style: {
      default: "",
      underline: "underline",
    },
  },
});

export default styles;
