import React from "react";
import styles from "./Breadcrumbs.styles";
import {
  Breadcrumbs as AriaBreadcrumbs,
  Breadcrumb,
} from "react-aria-components";
import { Icon } from "@/components/ui/Icon";
import { Link } from "react-router-dom";

export type Breadcrumb = {
  to: string;
  label: string;
};

export interface BreadcrumbsProps {
  crumbs: Breadcrumb[];
}

export const Breadcrumbs = ({ crumbs }: BreadcrumbsProps) => {
  const { wrapper, item, arrow, link } = styles();
  return (
    <nav aria-label="Breadcrumbs">
      <AriaBreadcrumbs className={wrapper()}>
        {crumbs.map((crumb, index) => (
          <Breadcrumb
            key={crumb.label}
            className={({ isDisabled }) => item({ isDisabled })}
          >
            <Link to={crumb.to} className={link()}>
              {crumb.label}
            </Link>
            {index < crumbs.length - 1 && (
              <span className={arrow()}>
                <Icon type="chevronRight" size="xs" />
              </span>
            )}
          </Breadcrumb>
        ))}
      </AriaBreadcrumbs>
    </nav>
  );
};
