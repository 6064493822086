import React, { useEffect } from "react";
import { SearchField } from "@/components/ui/SearchField";
import { Text } from "@/components/ui/Text";
import { EntitiesFlowDiagram } from "@/features/registrySearch/components/EntitiesFlowChart";
import { getName } from "country-list";
import { useRegistrySearchStore } from "@/features/registrySearch/store/useRegistrySearchStore";
import {
  useComapanySuggestionsExtended,
  useCompanySuggestionsQuick,
} from "@/features/companies/hooks/useCompanySuggestions";
import { RegistryDocument } from "@/components/CompanySearch/api/listRegistryDocuments";
import { Company } from "@/features/companies/types/company";
import { CompanyNameSuggestion } from "@/components/CompanySearch/api/listCompanyNameSuggestions";
import { RegistryDocuments } from "@/features/registrySearch/components/RegistryDocuments";
import {
  Filter,
  FilterOption,
  FilterOptionCheckbox,
} from "@/components/ui/Filter";

type Props = {
  initialSearchValue?: string;
  onDocumentsChange?: (documents: RegistryDocument[]) => void;
  onLegalEntityChange?: (legalEntity: Company | null) => void;
  onCompanySuggestionChange?: (selected: CompanyNameSuggestion | null) => void;
  onLoading?: (loading: boolean) => void;
};

const RegistrySearch = ({
  initialSearchValue,
  onDocumentsChange,
  onCompanySuggestionChange,
  onLegalEntityChange,
  onLoading,
}: Props) => {
  const {
    registrySearch,
    setRegistrySearch,
    companySuggestions = [],
    selectCompanySuggestion,
    selectedCompanySuggestion,
    filteredDocuments,
    legalEntity,
    setCompanySuggestions,
    loading,
    filterOptions,
    setFilterOptions,
  } = useRegistrySearchStore();

  const quickSuggestions = useCompanySuggestionsQuick(registrySearch);
  const extendedSuggestions = useComapanySuggestionsExtended(registrySearch);

  useEffect(() => {
    const allSuggestions = new Map();
    if (quickSuggestions.data) {
      quickSuggestions.data.forEach((suggestion) => {
        const key = `${suggestion.companyName}-${suggestion.countryCode}`;
        allSuggestions.set(key, suggestion);
      });
    }
    if (extendedSuggestions.data) {
      extendedSuggestions.data.forEach((suggestion) => {
        const key = `${suggestion.companyName}-${suggestion.countryCode}`;
        allSuggestions.set(key, suggestion);
      });
    }
    setCompanySuggestions(Array.from(allSuggestions.values()));
  }, [quickSuggestions.data, extendedSuggestions.data]);

  useEffect(() => {
    if (initialSearchValue) {
      setRegistrySearch(initialSearchValue);
    }
  }, []);

  useEffect(() => {
    if (onDocumentsChange) {
      onDocumentsChange(filteredDocuments);
    }
  }, [filteredDocuments]);

  useEffect(() => {
    if (onLegalEntityChange) {
      onLegalEntityChange(legalEntity);
    }
  }, [legalEntity]);

  useEffect(() => {
    if (onCompanySuggestionChange) {
      onCompanySuggestionChange(selectedCompanySuggestion);
    }
  }, [selectedCompanySuggestion]);

  useEffect(() => {
    if (onLoading) {
      onLoading(loading);
    }
  }, [loading]);

  const updateFilters = (options: FilterOption[]) => {
    setFilterOptions(options as FilterOptionCheckbox[]);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <SearchField
          label="Company Name"
          value={registrySearch}
          onChange={setRegistrySearch}
          autoFocus
          searchItems={
            companySuggestions?.map((suggestion) => ({
              id: suggestion.graphId,
              label: suggestion.companyName,
              description: getName(suggestion.countryCode),
            })) || []
          }
          onSearchSelection={(id) => {
            const company = companySuggestions.find(
              (suggestion) => suggestion.graphId === id,
            );
            if (company) {
              selectCompanySuggestion(company);
              setRegistrySearch(company.companyName);
            }
          }}
          fetching={quickSuggestions.isLoading}
          loadingText="Searching for companies..."
        />
      </div>
      {selectedCompanySuggestion?.id && (
        <div className="flex gap-3">
          <div className="flex flex-col gap-2.5 h-[500px] flex-1">
            <Text className="pl-3" type="label" color="label" strong>
              Select Legal Entity
            </Text>
            <div className="flex border rounded-layout h-full overflow-hidden">
              <div className="w-full bg-secondary">
                <EntitiesFlowDiagram />
              </div>
            </div>
          </div>
          {legalEntity?.id && (
            <div className="flex flex-col gap-2.5 h-[500px] w-96">
              <div className="relative flex justify-between items-center">
                <Text className="pl-3" type="label" color="label" strong>
                  Registry Documents
                </Text>
                {filterOptions.length > 0 && (
                  <div className="flex flex-1 justify-end h-4 items-center">
                    <Filter
                      buttonLabel="Filter"
                      options={filterOptions}
                      onSave={updateFilters}
                      maxHeight={400}
                    />
                  </div>
                )}
              </div>
              <div className="border rounded-layout h-full overflow-scroll">
                <RegistryDocuments />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RegistrySearch;
