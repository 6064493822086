import type React from "react";
import styles from "./ListItem.styles";
import { Text } from "@/components/ui/Text";

export interface ListItemProps {
  label: string;
  description?: string;
  suffix?: string;
}

export const ListItem = ({ label, description, suffix }: ListItemProps) => {
  const { wrapper, content } = styles();
  return (
    <div className={wrapper()}>
      <div className={content()}>
        <Text type="label">{label}</Text>
        {description && (
          <Text type="caption" color="label">
            {description}
          </Text>
        )}
      </div>
      {suffix && (
        <Text strong type="caption" color="label">
          {suffix}
        </Text>
      )}
    </div>
  );
};
