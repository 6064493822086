import { Button } from "@/components/ui/Button";
import { TagField } from "@/components/ui/TagField";
import { useCreateCompanyStore } from "@/features/companies/store/useCreateCompanyStore";
import React from "react";

const TabAliases = () => {
  const { nextTab, previousTab, aliases, setAliases, removeAlias } =
    useCreateCompanyStore();

  const handleAddAlias = (alias: string) => {
    setAliases([...aliases, { id: alias, label: alias }]);
  };

  const handleRemoveAlias = (alias: string) => {
    removeAlias(alias);
  };

  const handleSkip = () => {
    const newAliases = aliases.filter((alias) => alias.preventRemoval === true);
    setAliases(newAliases);
    nextTab();
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <TagField
          label="Name Aliases"
          tags={aliases}
          onRemoveTag={handleRemoveAlias}
          onAddTag={handleAddAlias}
          onEmptyEnter={nextTab}
          autoFocus
        />
      </div>
      <div className="flex gap-1.5">
        <Button
          variant="secondary"
          iconLeft="chevronLeft"
          onClick={previousTab}
        >
          Back
        </Button>
        <div className="flex-1" />
        <Button variant="secondary" onClick={handleSkip}>
          Skip
        </Button>
        <Button iconRight="chevronRight" onClick={nextTab}>
          Next
        </Button>
      </div>
    </>
  );
};

export default TabAliases;
