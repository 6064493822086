import { withVariants } from "@/utils/withVariants";

const styles = withVariants({
  slots: {
    base: "w-full flex flex-col gap-1.5",
    labelStyle:
      "flex pl-3 items-center gap-1 text-label text-size-label font-strong shrink-0",
    inputStyle:
      "focus-input outline-none border-base text-size-body text h-10 rounded px-3 placeholder:text-subdued w-full",
    asterisk: "text-primary",
    fieldError: "text-right text-danger text-size-label shrink-0",
  },

  variants: {
    focused: {
      true: {
        labelStyle: "text-primary",
      },
    },
    isDisabled: {
      true: {
        base: "disabled",
      },
    },
    size: {
      md: {},
      sm: {
        labelStyle: "px-1.5 gap-0.5 text-size-caption",
        inputStyle: "text-size-body text h-7 px-1.5",
        fieldError: "text-size-caption",
      },
    },
    isInvalid: {
      true: {
        inputStyle: "border-danger",
      },
    },
  },
});

export default styles;
