import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useCreateDealMutation } from "../../hooks/deals";
import TabLayout from "../TabLayout";
import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import UploadDocuments from "./subcomponents/UploadDocuments";
import { useEffect, useRef, useState } from "react";
import { useDealCreateDataStore, CreateDealStoreData } from "../../hooks/deals";
import RegistryDocuments from "./subcomponents/RegistryDocuments";
import React from "react";
import UploadFilesPixel from "./subcomponents/UploadFilesPixel";
import { FormValues } from "./subcomponents/types";

export default function DealsCreate() {
  const { orgId } = useParams();

  if (!orgId) {
    throw new Error("orgId is required");
  }
  const formRef = useRef<HTMLFormElement | null>(null);
  const navigate = useNavigate();
  const [dealPrepopulatedData, setDealPrepopulatedData] =
    useState<CreateDealStoreData | null>(null);
  const popDealData = useDealCreateDataStore((state) => state.popDealData);

  const [filesToUpload, setFilesToUpload] = useState<FileList | null>(null);
  const [dealId, setDealId] = useState<string | null>(null);
  const [filesUploadCompleted, setFilesUploadCompleted] = useState(false);
  const { control, handleSubmit, reset, register } = useForm<FormValues>({
    defaultValues: {
      name: "",
      companyName: dealPrepopulatedData?.companyName || "",
      website: dealPrepopulatedData?.website || null,
      companyAliases: [],
      registryFiles: dealPrepopulatedData?.registryFiles || [],
    },
  });
  const mutation = useCreateDealMutation(orgId as string);
  const {
    fields: companyAliases,
    append: appendCompanyAlias,
    remove: removeCompanyAlias,
  } = useFieldArray({
    control,
    name: "companyAliases",
  });
  const { fields: registryFiles, remove: removeRegistryFile } = useFieldArray({
    control,
    name: "registryFiles",
  });

  useEffect(() => {
    const prepopulatedData = popDealData();
    if (prepopulatedData) {
      setDealPrepopulatedData(prepopulatedData);

      reset({
        companyName: prepopulatedData.companyName || "",
        website: prepopulatedData.website || null,
        companyAliases: [],
        registryFiles: prepopulatedData.registryFiles || [],
      });
    }
  }, [popDealData]);

  useEffect(() => {
    if (dealId && filesUploadCompleted) {
      setFilesToUpload(null);
      navigate(`/orgs/${orgId}/deals/${dealId}`);
      reset();
    }
  }, [dealId, filesUploadCompleted]);

  useEffect(() => {
    if (dealId && (!filesToUpload || filesToUpload.length === 0)) {
      setFilesUploadCompleted(true);
    }
  }, [dealId]);

  const handleSaveClicked = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true }),
      );
    }
  };

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        { name: "Create", href: null },
      ]}
      buttons={[
        <button
          key="cancel"
          onClick={() => navigate(`/orgs/${orgId}/deals`)}
          type="button"
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Cancel
        </button>,

        <button
          key="create"
          type="submit"
          className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={handleSaveClicked}
        >
          Save
        </button>,
      ]}
    >
      <div className="p-4">
        <form
          ref={formRef}
          onSubmit={handleSubmit((values) => {
            mutation.mutate(
              {
                name: values.name,
                companyName: values.companyName,
                companyAliases: values.companyAliases.map(
                  (alias) => alias.value,
                ),
                website: values.website,
                companyIdInRegistry: dealPrepopulatedData?.companyId || "",
                registryFiles: values.registryFiles,
                countryCode: dealPrepopulatedData?.countryCode || "",
              },
              {
                onSuccess: (data) => {
                  setDealId(data.id);
                },
              },
            );
          })}
        >
          <div className="flex border-gray-200">
            <label className="text-lg text-bold text-gray-700">
              Create a new deal
            </label>
          </div>
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Deal Name
                  </label>
                  <div className="mt-1">
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: "Deal Name is required" }} // Validation rule
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <input
                            id="name"
                            type="text"
                            className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                              error
                                ? "border-red-500 focus:border-red-500 focus:ring-red-500"
                                : ""
                            }`}
                            {...field}
                            value={field.value ?? ""}
                          />
                          {error && (
                            <p className="mt-2 text-sm text-red-600">
                              {error.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="companyName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company Name
                  </label>
                  <div className="mt-1">
                    <Controller
                      name="companyName"
                      control={control}
                      render={({ field }) => (
                        <input
                          id="companyName"
                          type="text"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          {...field}
                          value={field.value ?? ""}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="companyName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company Aliases
                  </label>
                  <div className="space-y-2">
                    {companyAliases.map((field, index) => (
                      <div className="mt-1" key={index}>
                        <div className="flex">
                          <input
                            key={field.id}
                            {...register(`companyAliases.${index}.value`)}
                            type="text"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          <button
                            title="Remove"
                            type="button"
                            onClick={() => removeCompanyAlias(index)}
                            className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                          >
                            <XMarkIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      appendCompanyAlias({ value: "" });
                    }}
                    type="button"
                    className="rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <PlusIcon className="h-4 w-4" />
                  </button>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Website
                  </label>
                  <div className="mt-1">
                    <Controller
                      name="website"
                      control={control}
                      render={({ field }) => (
                        <input
                          id="website"
                          type="text"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          {...field}
                          value={field.value ?? ""}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="w-full h-full content-start grid grid-cols-2 gap-2 auto-rows-max self-start sm:col-span-6">
                  {dealPrepopulatedData?.registryFiles &&
                    dealPrepopulatedData?.registryFiles.length > 0 && (
                      <RegistryDocuments
                        registryFiles={registryFiles}
                        removeRegistryFile={removeRegistryFile}
                        register={register}
                      />
                    )}
                  <div
                    className={`${!dealPrepopulatedData?.registryFiles || !(dealPrepopulatedData?.registryFiles.length > 0) ? "col-span-2" : "col-span-1"}`}
                  >
                    <UploadDocuments onFileUpload={setFilesToUpload} />
                    {dealId && filesToUpload && filesToUpload.length > 0 && (
                      <UploadFilesPixel
                        orgId={orgId}
                        dealId={dealId}
                        files={filesToUpload}
                        onUploadComplete={() => setFilesUploadCompleted(true)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </TabLayout>
  );
}
