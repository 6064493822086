import { createTV } from "tailwind-variants";

export const withVariants = createTV({
  twMergeConfig: {
    classGroups: {
      fontSize: [
        "text-size-h1",
        "text-size-h2",
        "text-size-h3",
        "text-size-subheading",
        "text-size-body",
        "text-size-label",
        "text-size-caption",
      ],
      textColor: [
        "text",
        "text-inherit",
        "text-current",
        "text-transparent",
        "text-oncolor",
        "text-label",
        "text-subdued",
        "text-primary",
        "text-primary-subdued",
        "text-primary-oncolor",
        "text-success",
        "text-success-subdued",
        "text-success-oncolor",
        "text-warning",
        "text-warning-subdued",
        "text-warning-oncolor",
        "text-danger",
        "text-danger-subdued",
        "text-danger-oncolor",
        "text-brand-text",
        "text-brand-mark",
      ],
      backgroundColor: [
        "bg",
        "bg-inherit",
        "bg-current",
        "bg-transparent",
        "bg-selected",
        "bg-primary",
        "bg-primary-selected",
        "bg-primary-inverse",
        "bg-success",
        "bg-success-selected",
        "bg-success-inverse",
        "bg-warning",
        "bg-warning-selected",
        "bg-warning-inverse",
        "bg-danger",
        "bg-danger-selected",
        "bg-danger-inverse",
      ],
    },
    conflictingClassGroups: {
      fontSize: ["fontSize"],
      textColor: ["textColor"],
    },
  },
});
