import { Button } from "@/components/ui/Button";
import { TextField } from "@/components/ui/TextField";
import { useCreateCompanyStore } from "@/features/companies/store/useCreateCompanyStore";
import React, { useState } from "react";
import { Form } from "react-aria-components";

const TabCompany = () => {
  const { nextTab, setCompanyName, companyName, dealName, setDealName } =
    useCreateCompanyStore();
  const [companyNameValue, setCompanyNameValue] = useState(companyName);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    nextTab();
    setCompanyName(companyNameValue);
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <TextField
          name="Deal Name"
          label="Deal Name"
          required
          value={dealName}
          onChange={setDealName}
          autoFocus
          autoHighlight
        />
        <TextField
          name="Company Name"
          label="Company Name"
          required
          value={companyNameValue}
          onChange={setCompanyNameValue}
        />
        <div className="flex gap-1.5">
          <div className="flex-1" />
          <Button type="submit" iconRight="chevronRight" onClick={() => {}}>
            Next
          </Button>
        </div>
      </Form>
    </>
  );
};

export default TabCompany;
